
.identify-account-manage {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 20px;
    .table {
        flex: 1;
        overflow: hidden;
    }
    .paging-box {
        padding: 20px 0;
        text-align: center;
    }
    .form-box {
        padding-top: 20px;
        .el-form-item {
            display: flex;
            align-items: center;
        }
        ::v-deep .el-form-item__label {
            margin-bottom: 0;
            margin-right: 20px;
        }
        ::v-deep .el-form-item__content {
            flex: 1;
            margin-left: 0 !important;
        }
    }
    .add-account {
        margin-bottom: 20px;
        text-align: end;
    }
}

.avatar-box {
    width: 80px;
    height: 80px;
    box-sizing: border-box;
    border: 1px solid #eeeeee;
    position: relative;

    img {
        width: 100%;
        height: 100%;
    }

    .iconfont {
        line-height: 1;
        font-size: 14px;
        color: #dcdcdc;
        position: absolute;
        right: -20px;
        top: 0;
        cursor: pointer;
    }
}
.logo-uploader {
    ::v-deep.el-upload {
        width: 80px;
        height: 80px;
        background: #eeeeee;
        display: flex;
        align-items: center;
        justify-content: center;

        .logo-uploader-icon {
            font-size: 32px;
            color: #a0a0a0;
        }
    }
}
.avatar-box {
    width: 80px;
    height: 80px;
    .avatar-img {
        width: 100%;
        height: 100%;
        vertical-align: middle;
    }
}
::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
}
::v-deep .form-box .el-form-item__label {
    padding: 0;
    font-size: 16px !important;
    white-space: nowrap;
}
::v-deep .form-box .el-form-item__content {
    text-align: start;
}
